// ? ---
// ?	Imports
// ? ---
import React from 'react'
import debug from 'debug'

import '@fontsource-variable/montserrat'

import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

import theme from 'globals/client/theme'

import AuthProvider from 'components/AuthProvider'

// ? ---
// ?	Constants
// ? ---
const namespace = 'pages-_app'
const log = debug(`app:${namespace}`)

// ? ---
// ?	View
// ? ---
export default function App({ Component, pageProps: { ...pageProps } }: AppProps): JSX.Element {
	// * ---
	// *	 Setup
	// * ---

	// * ---
	// *	 Effect
	// * ---
	React.useEffect(() => {
		// * Debug
		localStorage.debug = 'app:*'
		// * Remove the server-side injected CSS.
		const jssStyles = document.querySelector('#jss-server-side')
		if (jssStyles) {
			jssStyles.parentElement?.removeChild(jssStyles)
		}
		log('..')
	}, [])

	// * ---
	// *	Return
	// * ---
	return (
		<ThemeProvider theme={theme}>
			<AuthProvider>
				<>
					<Head>
						<title>CDNR | Fast reliable CDN</title>
						<meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />
						<style>
							{`
								@media print {
									.MuiTooltip-popper {
										display: none !important;
									}
								}
							`}
						</style>
					</Head>
					<Script id={'reddit-pixel'} strategy={'beforeInteractive'}>
						{`!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_5645ja81', {"optOut":false,"useDecimalCurrencyValues":true,"aaid":"<AAID-HERE>","idfa":"<IDFA-HERE>"});rdt('track', 'PageVisit');`}
					</Script>

					<CssBaseline />

					<Component {...pageProps} />
				</>
			</AuthProvider>
		</ThemeProvider>
	)
}
