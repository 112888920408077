// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { deleteCookie, setCookie } from 'cookies-next'
import debug from 'debug'
import store from 'store2'

import { useRouter } from 'next/router'
import { endsWith, forEach, includes, toLower } from 'lodash'

import { loadOutseta } from 'globals/client/outseta'
import { CDNR_SUBSCRIPTION_ID } from 'globals/constants/account'
import { OUTSETA_ACCOUNT_ID_COOKIE } from 'globals/constants/cookies'
import { DEFAULT_LANDING_PATH, WEBSITE_URL } from 'globals/constants/urls'

// ? ---
// ?	Types
// ? ---
type Props = {
	children: JSX.Element
}

type OutsetaAuth = {
	setAccessToken: (token: string) => void
	getAccessToken: () => string
	getUser: () => any
	on: (eventName: string, callback: any) => void
	auth: { [key: string]: any }
	profile: {
		[key: string]: any
		open: (options: { [key: string]: any }) => void
	}
}

export interface IOutsetaUser {
	Email: string
	FirstName: string
	LastName: string
	MailingAddress: any
	PasswordMustChange: boolean
	PhoneMobile: string
	PhoneWork: string
	ProfileImageS3Url: string
	Title: any
	Timezone: any
	Language: any
	IPAddress: any
	Referer: any
	UserAgent: any
	LastLoginDateTime: string
	OAuthGoogleProfileId: any
	PersonAccount: any
	DealPeople: any
	DqaOnboardingInitial?: string
	DqaOnboardingFlow?: string
	LeadFormSubmissions: any
	Account: {
		Name: string
		ClientIdentifier: string
		InvoiceNotes: string
		IsDemo: boolean
		BillingAddress: any
		MailingAddress: {
			AddressLine1: any
			AddressLine2: any
			AddressLine3: any
			City: string
			State: string
			PostalCode: string
			Country: any
			GeoLocation: any
			ActivityEventData: any
			Uid: string
			Created: string
			Updated: string
		}
		AccountStage: number
		PaymentInformation: any
		PersonAccount: any
		Subscriptions: any
		Deals: any
		LastLoginDateTime: string
		AccountSpecificPageUrl1: string
		AccountSpecificPageUrl2: string
		AccountSpecificPageUrl3: string
		AccountSpecificPageUrl4: string
		AccountSpecificPageUrl5: string
		RewardFulReferralId: any
		HasLoggedIn: boolean
		AccountStageLabel: string
		DomainName: any
		LatestSubscription: any
		CurrentSubscription: {
			BillingRenewalTerm: number
			Account: any
			Plan: {
				Name: string
				Uid: string
			}
			Quantity: any
			StartDate: string
			EndDate: any
			RenewalDate: string
			NewRequiredQuantity: any
			IsPlanUpgradeRequired: boolean
			PlanUpgradeRequiredMessage: any
			SubscriptionAddOns: any[]
			DiscountCouponSubscriptions: any[]
			LatestInvoice: any
			Rate: number
			ActivityEventData: any
			Uid: string
			Created: string
			Updated: string
		}
		PrimaryContact: {
			Email: string
			FirstName: string
			LastName: string
			MailingAddress: any
			PasswordMustChange: boolean
			PhoneMobile: string
			PhoneWork: string
			ProfileImageS3Url: any
			Title: any
			Timezone: any
			Language: any
			IPAddress: any
			Referer: any
			UserAgent: any
			LastLoginDateTime: any
			OAuthGoogleProfileId: any
			PersonAccount: any
			DealPeople: any
			LeadFormSubmissions: any
			Account: any
			FullName: string
			OAuthIntegrationStatus: number
			UserAgentPlatformBrowser: string
			HasUnsubscribed: boolean
			ActivityEventData: any
			Uid: string
			Created: string
			Updated: string
		}
		PrimarySubscription: any
		RecaptchaToken: any
		LifetimeRevenue: number
		ActivityEventData: any
		Uid: string
		Created: string
		Updated: string
		CdnrAccount?: boolean
		CdnrData?: string
	}
	FullName: string
	OAuthIntegrationStatus: number
	UserAgentPlatformBrowser: string
	HasUnsubscribed: boolean
	ActivityEventData: any
	Uid: string
	Created: string
	Updated: string
}

export interface IAuthContext {
	user: IOutsetaUser | null
	getToken: () => string | null
	isLoading: boolean
	isAuth: boolean
	isLoggingIn: boolean
	logout: (options?: { redirect?: boolean | string }) => void
	gotoLogin: () => void
	openLogin: (options?: { [key: string]: any }) => void
	openSignup: (options?: { [key: string]: any }) => void
	openProfile: (options?: { [key: string]: any }) => void
	openPlan: (options?: { [key: string]: any }) => void
	openUpgrade: (options?: { [key: string]: any }) => void
	reload: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-AuthProvider'
const log = debug(`app:${namespace}`)

const AuthContext = createContext({} as IAuthContext)

export function useAuth(): IAuthContext {
	return useContext(AuthContext)
}

// ? ---
// ?	Provider
// ? ---
export default function AuthProvider({ children }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	const router = useRouter()
	log('.')
	const outsetaRef = useRef<null | OutsetaAuth>(null)
	const [isLoading, $isLoading] = useState(true)
	const [isAuth, $isAuth] = useState(false)
	const [isLoggingIn, $isLoggingIn] = useState(false)
	const [user, $user] = useState<null | IOutsetaUser>(null)
	const [reloader, $reloader] = useState(0)

	// * ---
	// *	Reload Kicker
	// * ---
	const reload = () => {
		$reloader(reloader + 1)
	}

	// * ---
	// *	Handle Auth Updates
	// * ---
	useEffect(() => {
		const init = async () => {
			outsetaRef.current = (await loadOutseta()) as OutsetaAuth
			handleOutsetaUserEvents(updateUser)

			let accessToken = router.query.access_token
			if (Array.isArray(accessToken)) {
				accessToken = accessToken[0]
			}

			if (accessToken) {
				outsetaRef.current.setAccessToken(accessToken)
				$isLoggingIn(true)
				await updateUser(true)
			} else if (outsetaRef.current.getAccessToken()) {
				$isLoggingIn(false)
				await updateUser()
			} else {
				$isLoggingIn(false)
				$isLoading(false)
			}
		}

		$isLoading(true)
		init().then()

		return () => {
			// Clean up user related event subscriptions
			handleOutsetaUserEvents(() => {
				log('handleOutsetaUserEvents')
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.query.access_token, reloader])

	// * ---
	// *	Methods
	// * ---
	const getToken = () => {
		if (outsetaRef.current === null) {
			return null
		}
		return outsetaRef.current.getAccessToken()
	}
	const removeAccessToken = (url: string) => {
		log('removeAccessToken', url)
		let response = `${url}`
		if (includes(response, 'access_token')) {
			const params = new URLSearchParams(response.split('?')[1])
			params.delete('access_token')
			const otherParams = params.toString()
			if (otherParams.length === 0) {
				response = response.split('?')[0]
			} else {
				response = `${response.split('?')[0]}?${otherParams}`
			}
		}
		log('removeAccessToken', { url, response })
		return response
	}

	const updateUser = async (redirect?: boolean) => {
		log('updateUser', redirect)
		if (outsetaRef.current === null) {
			console.warn('updateUser: outsetaRef.current === null')
			return
		}
		const outsetaUser = await outsetaRef.current.getUser()
		setCookie(OUTSETA_ACCOUNT_ID_COOKIE, outsetaUser.Account.Uid)
		log('updateUser', outsetaUser, { env: process.env.NODE_ENV, hosts: process.env.NEXT_PUBLIC_HOSTS })
		$user(outsetaUser)
		$isAuth(true)

		await router.push(removeAccessToken(router.asPath))
		$isLoading(false)
	}

	const handleOutsetaUserEvents = (onEvent: any) => {
		if (outsetaRef.current === null) {
			console.warn('handleOutsetaUserEvents: outsetaRef.current === null')
			return
		}
		outsetaRef.current.on('subscription.update', onEvent)
		outsetaRef.current.on('profile.update', onEvent)
		outsetaRef.current.on('account.update', onEvent)
	}

	const logout: IAuthContext['logout'] = async () => {
		if (outsetaRef.current === null) {
			console.warn('logout: outsetaRef.current === null')
			return
		}
		outsetaRef.current.setAccessToken('')
		deleteCookie(OUTSETA_ACCOUNT_ID_COOKIE)

		// * Clear outseta & cache storage
		forEach(store.keys(), (key) => {
			if (includes(toLower(key), 'outseta') || includes(key, 'cachedAt:') || includes(key, 'apollo')) {
				store.remove(key)
			}
		})

		$user(null)
		$isAuth(false)
		$isLoading(false)

		location.reload()
	}

	const gotoLogin = async () => {
		await logout()
	}

	const openLogin: IAuthContext['openLogin'] = async (options) => {
		log('openLogin', options)
		if (outsetaRef.current === null) {
			console.warn('openLogin: outsetaRef.current === null')
			return
		}

		let destination = location.href
		if (location.pathname === '/' || endsWith(location.href, '/login')) {
			destination = `${location.origin}${DEFAULT_LANDING_PATH}`
		}

		outsetaRef.current.auth.open({
			widgetMode: 'login',
			authenticationCallbackUrl: `${destination}`,
			...options,
		})
	}

	const openSignup: IAuthContext['openSignup'] = async () => {
		log('openSignup')
		if (outsetaRef.current === null) {
			console.warn('openSignup: outsetaRef.current === null')
			return
		}

		outsetaRef.current.auth.open({
			widgetMode: 'register',
			planUid: CDNR_SUBSCRIPTION_ID,
			planPaymentTerm: 'month',
			skipPlanOptions: true,
			registrationUrl: `${WEBSITE_URL}/?registration`,
			postRegistrationUrl: `${WEBSITE_URL}/?post-registration`,
			postLoginUrl: `${WEBSITE_URL}/?post-login`,
			registrationConfirmationUrl: `${WEBSITE_URL}/?registration-confirmation`,
			passwordResetUrl: `${WEBSITE_URL}/?password-reset`,
			postPasswordResetRedirectUrl: `${WEBSITE_URL}/?post-password-reset-redirect`,
			postForgotPasswordRedirectUrl: `${WEBSITE_URL}/?post-forgot-password-redirect`,
			authenticationCallbackUrl: `${WEBSITE_URL}/?authentication-callback`,
			redirectUrl: `${WEBSITE_URL}/?redirect`,
			registrationDefaults: {
				Account: {
					CdnrAccount: true,
				},
			},
		})
	}

	const openProfile: IAuthContext['openProfile'] = async (options) => {
		log('openProfile', options)
		if (outsetaRef.current === null) {
			console.warn('openProfile: outsetaRef.current === null')
			return
		}
		outsetaRef.current.profile.open({ tab: 'profile', ...options })
	}

	const openPlan: IAuthContext['openPlan'] = async (options) => {
		log('openPlan', options)
		if (outsetaRef.current === null) {
			console.warn('openPlan: outsetaRef.current === null')
			return
		}
		outsetaRef.current.profile.open({ tab: 'plan', ...options })
	}

	const openUpgrade: IAuthContext['openProfile'] = async (options) => {
		log('openUpgrade', options)
		if (outsetaRef.current === null) {
			console.warn('openUpgrade: outsetaRef.current === null')
			return
		}
		outsetaRef.current.profile.open({
			tab: 'planChange',
			...options,
		})
	}

	// * ---
	// *	Return
	// * ---
	return (
		<AuthContext.Provider
			value={{
				user,
				getToken,
				isLoading,
				isLoggingIn,
				isAuth,
				logout,
				gotoLogin,
				openLogin,
				openSignup,
				openProfile,
				openPlan,
				openUpgrade,
				reload,
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}
