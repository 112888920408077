// * Hostnames
export const BASE_HOSTNAME = 'cdnr.io'

// * Paths
export const POST_LOGOUT_PATH = '/'
export const DEFAULT_LANDING_PATH = '/'

// * URLs
export const WEBSITE_URL = 'https://cdnr.io'
export const CDN_API_BASE_URL = 'https://api.cdnr.io'
export const CDN_LINK_BASE_URL = 'https://cdnr.io'

// * URLs
export const OUTSETA_BASE_URL = 'https://dqa.outseta.com/api/v1'
