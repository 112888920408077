// ? ---
// ?	Imports
// ? ---
import { amber, blueGrey, lightGreen, pink } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

// ? ---
// ?	Constants
// ? ---
const BRAND_PRIMARY = '#FF0099'
const BRAND_BACKGROUND = '#0E0525'
const BRAND_PAPER = '#170E2F'

// ? ---
// ?	Create Theme
// ? ---
let theme = createTheme({
	overrides: {
		MuiCssBaseline: {
			'@global': {
				body: {
					backgroundColor: BRAND_BACKGROUND,
					fontWeight: 600,
				},
			},
		},
	},
	typography: {
		fontFamily: 'Montserrat Variable',
		fontWeight: 600,
		fontWeightLight: 600,
		fontWeightRegular: 600,
		fontWeightMedium: 600,
		fontWeightBold: 700,
		h1: {
			fontSize: '1.6rem',
			fontWeight: `700 !important`,
			lineHeight: 1.2,
		},
		h2: {
			fontSize: '1.4rem',
			fontWeight: 600,
		},
		h3: {
			fontSize: '1.2rem',
			fontWeight: 600,
		},
		h4: {
			fontSize: '1.1rem',
			fontWeight: 600,
		},
		subtitle1: {
			fontWeight: 600,
		},
		subtitle2: {
			fontWeight: 600,
		},
		body1: {
			fontWeight: 500,
		},
		body2: {
			fontWeight: 500,
		},
	},
	palette: {
		mode: 'dark',
		background: {
			default: BRAND_BACKGROUND,
			paper: BRAND_PAPER,
		},
		primary: {
			light: BRAND_PRIMARY,
			main: BRAND_PRIMARY,
			dark: BRAND_PRIMARY,
		},
		secondary: {
			light: blueGrey[300],
			main: blueGrey[400],
			dark: blueGrey[500],
		},
		error: {
			light: pink[100],
			main: pink[300],
			dark: pink[500],
		},
		warning: {
			light: amber[100],
			main: amber[300],
			dark: amber[500],
		},
		success: {
			light: lightGreen[300],
			main: lightGreen[400],
			dark: lightGreen[500],
		},
	},
	spacing: 16,
	shape: {
		borderRadius: 6,
	},
	transitions: {
		duration: {
			enteringScreen: 225,
			leavingScreen: 195,
		},
	},
})

// ? ---
// ?	Export Theme
// ? ---
export default theme
